import {SortModelItem} from 'ag-grid-community';

type ResponseType = 'json' | 'blob';

class UpsRequestOptions {
  constructor(
    public params?: any,
    public body?: any,
    public headers?: any,
    public responseType: ResponseType = 'json'
  ) {
  }
}

export class UpsRequest {
  defaultParams: object = {};

  constructor(
    private url: string,
    private method = 'get',
    private options: UpsRequestOptions = {
      params: {},
      body: {},
      headers: {},
      responseType: 'json',
    }
  ) {
    this.updateParams(options.params);
    this.updateBody(options.body);
  }

  getUrl(): string {
    return this.url;
  }

  updateUrl(newUrl: string): void {
    this.url = newUrl;
  }

  getMethod(): string {
    return this.method;
  }

  updateMethod(newMethod: string): void {
    this.method = newMethod;
  }

  getResponseType(): ResponseType {
    return this.options.responseType;
  }

  getParams() {
    return this.options.params;
  }

  putParam(key: string, value: any) {
    this.options.params[key] = value;
    this.updateParams(this.options.params);
  }

  updateParams(params: object) {
    this.options.params = {
      ...params,
      ...this.defaultParams,
    };
  }

  setHeaders(headers: object) {
    this.options.headers = {
      ...headers
    };
  }

  getHeaders() {
    return this.options.headers;
  }

  resetParams(): void {
    this.options.params = {...this.defaultParams};
  }

  getBody() {
    return this.options.body;
  }

  setBody(body: any) {
    this.options.body = body;
  }

  updateBody(body: object, clear = true) {
    if (clear) {
      this.clearBody();
    }
    this.options.body = {
      ...this.options.body,
      ...body,
    };
  }

  clearBody() {
    this.options.body = {};
  }

  setSortParams(sortData: SortModelItem) {
    this.putParam('order_column', sortData.colId);
    this.putParam('order_type', sortData.sort.toLowerCase());
  }

  removeSortParams() {
    delete this.options.params.sort;
  }
}

export class UpsGetRequest extends UpsRequest {
  constructor(url: string, params?: object) {
    super(url, 'get', new UpsRequestOptions({...params}));
  }
}

export class UpsTableRequest extends UpsRequest {
  static DEFAULT_PAGE = 1;
  static DEFAULT_SIZE = 15;
  override defaultParams: object = {};

  constructor(url: string, params?: object) {
    super(url, 'get', new UpsRequestOptions({...params}));
  }

  setPageParams(page: number, size: number) {
    this.putParam('page', page);
    this.putParam('per_page', size);
  }

  setFilterParams(filter: object) {
    const params = {
      ...this.getParams(),
      ...filter,
    };
    this.updateParams(params);
  }

  removeDefaultQueries(queries: any) {
    if (queries.size === UpsTableRequest.DEFAULT_SIZE) {
      delete queries.size;
    }

    if (queries.page === UpsTableRequest.DEFAULT_PAGE) {
      delete queries.page;
    }
    if (!queries.query) {
      delete queries.query;
    }
    return queries;
  }
}

export class UpsPostRequest extends UpsRequest {
  constructor(url: string, body: object) {
    super(url, 'post', new UpsRequestOptions({}, body));
  }
}

export class UpsPatchRequest extends UpsRequest {
  constructor(url: string, body: object) {
    super(url, 'patch', new UpsRequestOptions({}, body));
  }
}

export class UpsDeleteRequest extends UpsRequest {
  constructor(url: string) {
    super(url, 'delete', new UpsRequestOptions());
  }
}
